import "./modules/add-to-cart.js";
import "./modules/forms.js";
import "./modules/sliders.js";
import "./modules/search-ui.js";
import "./modules/accordions.js";
import "./modules/mobile-navigation.js";
import "./modules/videos.js";
import "./modules/quotation.js";

// Locatie filter
$(".js-location-filter").on("change", function () {
	$("#location-filter-form").trigger("submit");
});

window.onscroll = function () {
	myFunction();
};

var header = document.getElementById("header");
var sticky = header.offsetTop;

function myFunction() {
	if (window.pageYOffset > sticky) {
		header.classList.add("sticky");
	} else {
		header.classList.remove("sticky");
	}
}

// Homepage - Form
const choiceButtons = document.querySelectorAll(".choice-btn");
const selectedChoices = new Set();

// The id on the live site is 11
const selectedInput = document.getElementById("input_11_6");

choiceButtons.forEach((button) => {
	button.addEventListener("click", () => {
		button.classList.toggle("is-active");

		if (button.classList.contains("is-active")) {
			selectedChoices.add(button.id);
		} else {
			selectedChoices.delete(button.id);
		}

		selectedInput.value = Array.from(selectedChoices).join(", ");
		console.log(selectedInput.value);
	});
});

// Flex content - Form
document.addEventListener("DOMContentLoaded", function () {
	const urlParams = new URLSearchParams(window.location.search);

	const choices = urlParams.get("choices");
	const postcode = urlParams.get("postcode");
	const huisnummer = urlParams.get("huisnummer");

	if (choices) {
		const selectedChoices = choices.split(",").map((choice) => choice.trim());

		selectedChoices.forEach((choice) => {
			const checkbox = document.querySelector(
				`input[type="checkbox"][value="${choice}"]`
			);
			if (checkbox) {
				checkbox.checked = true; // Vink de checkbox aan
			}
		});
	}

	if (postcode) {
		const postcodeField = document.querySelector('input[name="input_5"]');
		if (postcodeField) {
			postcodeField.value = postcode;
		}
	}

	if (huisnummer) {
		const huisnummerField = document.querySelector('input[name="input_4"]');
		if (huisnummerField) {
			huisnummerField.value = huisnummer;
		}
	}
});
