const $addToCart = $(".js-add-to-cart");
var isAjax = false;

$addToCart.on("submit", function (e) {
	e.preventDefault();

	if (isAjax) {
		return;
	}

	const $this = $(this);
	let data = $this.serializeArray();
	data.push({ name: "action", value: "app_add_to_cart" });

	const settings = {
		url: my_ajax_obj.ajax_url,
		method: "POST",
		data: $.param(data), // Serializing the data correctly
		beforeSend: function () {
			isAjax = true;
		},
	};

	$.ajax(settings)
		.done(function (response) {
			var cartTotal = response.data.cart_total;
			var cartTotalDisplay = document.getElementById("cartTotal");

			if (cartTotal) {
				cartTotalDisplay.innerHTML = cartTotal;
			} else {
				cartTotalDisplay.innerHTML = "0";
			}

			var priceField = document.getElementById("input_1_3");
			if (priceField) {
				priceField.value = cartTotal; // Werk het prijsveld bij met het nieuwe totaal
			}

			$(".js-cart-items").replaceWith(response.data.cart_html); // Update de winkelwagen HTML
			toggleCart();
		})
		.fail(function (jqXHR, textStatus, errorThrown) {
			console.log("Error: " + textStatus, errorThrown);
		})
		.always(function () {
			isAjax = false;
		});
});

const toggleCart = () => {
	$(".shop-cart").toggleClass("is-active");
	$(".overlay").toggleClass("is-active");
	$("body").toggleClass("overflow-hidden");
};

$(".overlay").on("click", function (e) {
	toggleCart();
});

$(".js-close-cart").on("click", function () {
	toggleCart();
});
