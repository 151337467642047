$(".js-form-calculate").each(() => {
	$("input[type='number']").keyup(function () {
		const $length = $("input[name='lengte']");
		const $width = $("input[name='breedte']");
		const $height = $("input[name='hoogte']");

		// Parse values as floating-point numbers
		const lengthResult = parseFloat($length.val());
		const widthResult = parseFloat($width.val());
		const heightResult = parseFloat($height.val());

		if (!isNaN(lengthResult) && !isNaN(widthResult) && !isNaN(heightResult)) {
			const $total = lengthResult * widthResult * heightResult;

			$(".form__total p span").text($total.toFixed(2)); // Show two decimal places
			$(".js-submit-cooling-wrapper").css("display", "block");
			calculateCooling();
			$(".form__result").css("display", "block");
		} else {
			$(".js-submit-cooling-wrapper").css("display", "none");
			$(".form__total p span").text("0");
			$(".form__result").css("display", "none");
		}
	});
});

function calculateCooling() {
	const $roomSizeElem = $(".form__total p span");
	const $radio = $('input[name="radio_group"]');

	// Parse room size as a floating-point number
	let roomSize = parseFloat($roomSizeElem.text());
	if (isNaN(roomSize)) {
		console.log("Invalid room size");
		return;
	}

	let factor;
	$radio.each(function () {
		if ($(this).is(":checked")) {
			factor = parseFloat($(this).val()); // Use parseFloat for factors
		}
	});

	if (isNaN(factor)) {
		console.log("Invalid factor");
		return;
	}

	let watt = roomSize * factor;

	$(".js-watt").text(watt.toFixed(2)); // Show two decimal places
}

// Recalculate when the factor changes
$('input[name="radio_group"]').on("change", () => {
	calculateCooling();
});

$("#gas").on("change", (event) => {
	const selectedValue = $(event.target).val();
	const $gasCol = $(".js-gas-col");

	if (selectedValue === "yes") {
		$gasCol.css("display", "block");
	} else {
		$gasCol.css("display", "none");
	}
});

$(".form__controls--file").each((i, el) => {
	const $elem = $(el);
	const targetInput = $elem.find("input[type='file']");
	const targetSpan = $elem.find("span.chosen-file");

	targetInput.change(function () {
		const filename = targetInput.val().split("\\").pop();

		targetSpan.text(filename);
	});
});

if ($(".js-consumption").length > 0) {
	const energyMap = {
		apartment: "2950",
		"family-home": "4000",
		"2-under-1-roof": "4600",
		villa: "5300",
		company: "10000",
	};

	const gasMap = {
		apartment: "1020",
		"family-home": "1450",
		"2-under-1-roof": "1720",
		villa: "1800",
		company: "5000",
	};

	$(".js-consumption").on("change", function () {
		const value = $(this).val();

		if (energyMap.hasOwnProperty(value)) {
			$(".js-energy").val(energyMap[value]);
		}

		if (gasMap.hasOwnProperty(value)) {
			$(".js-gas-usage").val(gasMap[value]);
		}
	});
}

$(".js-form-steps").each((i, el) => {
	const $form = $(el);
	const prevButton = $form.find(".js-prev");
	const nextButton = $form.find(".js-next");
	const progressBar = $form.find(".form__progress-bar span");
	const currentStep = $form.find(".form__progress p:nth-child(1)");
	const steps = $form.find(".form__steps");
	const submitButton = $form.find(".js-submit");
	const completeScreen = $form.find(".form__complete");
	const footSection = $form.find(".form__foot");

	function validate() {
		let success = true;

		const activeStep = $(".form__step.is-active");
		const requiredFields = activeStep.find(":input[required]");

		requiredFields.each(function (i, el) {
			const $field = $(el);

			if ($field.val() === "") {
				success = false;
				$field.css("box-shadow", "0 0 3px red");
			} else {
				success = true;
				$field.css("box-shadow", "0 0 3px transparent");
			}
		});

		return success;
	}

	function showAndHide() {
		const activeStep = $(".form__step.is-active");
		const activeStepIndex = $(".form__step.is-active").index();

		if (activeStepIndex === 0) {
			nextButton.addClass("is-active");
			prevButton.removeClass("is-active");

			currentStep.text("1");
			progressBar.css("width", "33.3%");
		} else if (activeStepIndex === 1) {
			prevButton.addClass("is-active");

			currentStep.text("2");
			progressBar.css("width", "66.6%");
		} else {
			nextButton.removeClass("is-active");

			currentStep.text("3");
			progressBar.css("width", "100%");
		}
	}

	$(".js-next").on("click", function (e) {
		e.preventDefault();

		validate();

		if (validate() === true) {
			const activeStep = $(".form__step.is-active");
			activeStep.removeClass("is-active");
			activeStep.next().addClass("is-active");

			showAndHide();
		}
	});

	$(".js-prev").on("click", function (e) {
		e.preventDefault();

		const activeStep = $(".form__step.is-active");
		activeStep.removeClass("is-active");
		activeStep.prev().addClass("is-active");

		showAndHide();
	});

	$(".js-submit").on("click", function (e) {
		e.preventDefault();

		validate();

		if (validate() === true) {
			const dataObject = {};
			const $form = $(".js-form");
			const formData = $form.serializeArray();

			formData.forEach((item) => {
				// Only add the item to the object if it's not empty
				if (item.value !== "") {
					dataObject[item.name] = item.value;
				}
			});
		}
	});
});

// Getting the button that toggles the gas usage helper and the gas usage helper container

const unknownGasUsageBtn = document.querySelector(".js-unknown-gas-usage-btn");
const gasUsagePersonsContainer = document.querySelector(
	".js-gas-usage-persons"
);

if (unknownGasUsageBtn) {
	// Onclick the the gas usage helper container gets shown
	unknownGasUsageBtn.addEventListener("click", () => {
		gasUsagePersonsContainer.classList.toggle("hidden");
	});
}

// Gathering all the buttons of the gas usage helper and the input field that gets shown
const buttons = document.querySelectorAll(".js-amount-of-persons");
const energy = document.querySelector(".js-energy");

let kilowatts = 0;

buttons.forEach((button) => {
	button.addEventListener("click", () => {
		console.log(button.textContent);

		switch (button.textContent) {
			case "1":
				kilowatts = 1200;
				break;
			case "2-3":
				kilowatts = 2350;
				break;
			case "4-5":
				kilowatts = 3500;
				break;
			case "6+":
				kilowatts = 7500;
				break;
			default:
				kilowatts = 0;
				break;
		}
		energy.value = kilowatts; // Set the input value
		console.log(`Selected kilowatts: ${kilowatts}`);
	});
});

/*
 ** choice buttons, this is used on the homepage hero
 */

// const choiceButtons = document.querySelectorAll(".choice-btn");
// const selectedInput = document.getElementById("input_4_6");

// // Houdt de geselecteerde keuzes bij
// const selectedChoices = new Set();

// choiceButtons.forEach((btn) => {
// 	btn.addEventListener("click", () => {
// 		// Toggle de 'is-active' class op de geklikte knop
// 		btn.classList.toggle("is-active");

// 		// Werk de selectedChoices set bij
// 		if (btn.classList.contains("is-active")) {
// 			selectedChoices.add(btn.id);
// 		} else {
// 			selectedChoices.delete(btn.id);
// 		}

// 		// Update de waarde van het verborgen inputveld
// 		selectedInput.value = Array.from(selectedChoices).join(", ");
// 	});
// });

var uploadInput = document.getElementById("upload");

if (uploadInput) {
	uploadInput.addEventListener("change", function (event) {
		const file = event.target.files[0];
		if (!file) return;

		const formData = new FormData();
		formData.append("upload", file);

		fetch("upload-handler.php", {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status === "success") {
					const hiddenField = document.querySelector('input[name="input_7"]');
					hiddenField.value = data.url;

					document.querySelector(".chosen-file").textContent = file.name;
				} else {
					alert("Fout bij uploaden: " + data.message);
				}
			})
			.catch((error) => console.error("Upload error:", error));
	});
}

jQuery(document).ready(function ($) {
	$("#gform_submit_button_10").on("click", function (e) {
		e.preventDefault(); // Voorkom standaard formulier verzenden

		var form = $(this).closest("form");
		const completeScreen = $(".form__complete");
		const footSection = $(".form__foot");
		const steps = $(".form__steps");

		gformSubmit(form);

		const activeStep = $(".form__step.is-active");
		activeStep.removeClass("is-active");
		completeScreen.addClass("is-active");
		footSection.hide();
		steps.hide();
	});
});

function gformSubmit(form) {
	var formData = form.serialize();

	$.ajax({
		type: "POST",
		url: form.attr("action"),
		data: formData,
		success: function (response) {
			// Toon success bericht
			form.find(".gform_confirmation_message").show();
			form.hide(); // Optioneel: verberg formulier
		},
		error: function (xhr, status, error) {
			// Toon foutmelding
			console.error("Formulier verzending mislukt:", error);
		},
	});
}
